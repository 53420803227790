import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useParams } from "react-router-dom";
import { IoPaperPlane } from "react-icons/io5";

import api from "../../services/api";
import Modal from "../../components/Modal";
import { WILLIAM } from "../../services/config";

const NDA_STATUS_SPAN = {
  "document.uploaded": <span className="text-yellow-600 px-3 text-xs border border-yellow-600  p-1 rounded text-center">Brouillon - le NDA n'est pas encore envoyé</span>,
  "document.sent": <span className="text-yellow-600 px-3 text-xs border border-yellow-600  p-1 rounded text-center">Envoyé - en attente de la signature des 2 parties</span>,
  "document.completed": <span className="text-green-700 text-xs border border-green-600  p-1 rounded text-center px-3">Complété</span>,
  "document.expired": <span className="text-red-500 px-3 text-xs border border-red-500 p-1 rounded text-center">Expiré</span>,
  "document.error_sending": (
    <span className="text-red-500 px-3 text-xs border border-red-500 p-1 rounded text-center">Erreur lors de l'envoi, il faut envoyer manuellement le NDA</span>
  ),
};

const STATUS_SPAN = {
  PENDING: <span className="text-yellow-600 px-3 text-xs border border-yellow-600  p-1 rounded text-center">En attente</span>,
  ACCEPTED: <span className="text-yellow-600 px-3 text-xs border border-yellow-600  p-1 rounded text-center">Acceptée - le NDA n'est pas encore envoyé</span>,
  ONGOING: <span className="text-green-700 text-xs border border-green-600  p-1 rounded text-center px-3">En cours</span>,
  REFUSED: <span className="text-red-500 px-3 text-xs border border-red-500 p-1 rounded text-center">Refusée</span>,
  STOPPED: <span className="text-gray-500 px-3 text-xs border border-gray-500 p-1 rounded text-center">Arrêtée</span>,
};

const View = () => {
  const { id } = useParams();
  const [discussion, setDiscussion] = useState();
  const [userTo, setUserTo] = useState();
  const [userFrom, setUserFrom] = useState();
  const [view, setView] = useState("infos");
  const [loading, setLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/discussion/${id}`);
        if (!res.ok) return toast.error("Une erreur est survenue");

        res.data.created_at = new Date(res.data.created_at);
        res.data.updated_at = new Date(res.data.updated_at);

        const res2 = await api.get(`/user/${res.data.user_to_id}`);
        if (!res2.ok) return toast.error("Une erreur est survenue");
        const res3 = await api.get(`/user/${res.data.user_from_id}`);
        if (!res3.ok) return toast.error("Une erreur est survenue");

        setDiscussion(res.data);
        setUserTo(res2.data);
        setUserFrom(res3.data);
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
      }
    };
    fetchData();
  }, []);

  const handleChangeStatus = async (status) => {
    try {
      const res = await api.put(`/discussion/${id}`, { status });
      if (res.ok) setDiscussion(res.data);
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  const handleAccept = async () => {
    setLoading(true);
    try {
      const res = await api.post(`/discussion/${id}/accept`);
      if (!res.ok) throw new Error("Une erreur est survenue");
      setDiscussion(res.data);
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  if (!discussion || !userTo || !userFrom) return <></>;

  return (
    <div className="flex flex-col p-8">
      <header className="w-full mb-8">
        <h1 className="text-3xl font-semibold text-black mb-2">Discussion</h1>
      </header>

      <div className="flex justify-between flex-wrap items-center  mb-5 gap-3">
        <div className="flex gap-5">
          <button type="button" className={`rounded-lg px-4 py-2 ${view === "infos" ? "bg-gray-100" : "border border-secondary"}`} onClick={() => setView("infos")}>
            Informations
          </button>
          <button type="button" className={`rounded-lg px-4 py-2 ${view === "raw" ? "bg-gray-100" : "border border-secondary"}`} onClick={() => setView("raw")}>
            Données brutes
          </button>
        </div>
        <div className="flex gap-5">
          {discussion.nda_user_from_signed && discussion.nda_user_to_signed ? (
            <div>
              <button type="button" className="primary-button" onClick={() => setShowEmailModal(true)}>
                Envoyer un e-mail
              </button>
              <SendEmailModal
                discussion={discussion}
                setDiscussion={setDiscussion}
                userTo={userTo}
                userFrom={userFrom}
                show={showEmailModal}
                onClose={() => setShowEmailModal(false)}
              />
            </div>
          ) : (
            <div />
          )}

          {discussion.status === "PENDING" ? (
            <div className="flex gap-3">
              <button type="button" className="rounded-lg px-4 py-2 bg-green-500 text-white" onClick={handleAccept} disabled={loading}>
                {loading ? <div className="border-t border-white rounded-full w-6 h-6 animate-spin" /> : "Accepter"}
              </button>
              <button type="button" className="rounded-lg px-4 py-2 bg-red-500 text-white" onClick={() => handleChangeStatus("REFUSED")}>
                Refuser
              </button>
            </div>
          ) : (
            <div>
              <select className="select" value={discussion.status} onChange={(e) => handleChangeStatus(e.target.value)}>
                <option value="PENDING">En attente</option>
                <option value="ACCEPTED">Acceptée</option>
                <option value="REFUSED">Refusée</option>
                <option value="ONGOING">En cours</option>
                <option value="STOPPED">Arrêtée</option>
              </select>
            </div>
          )}
        </div>
      </div>

      {view === "infos" ? (
        <Infos discussion={discussion} setDiscussion={setDiscussion} userTo={userTo} userFrom={userFrom} />
      ) : (
        <div className="col-span-2 bg-gray-900 rounded-lg p-5 flex flex-col gap-2">
          <pre className="text-white text-sm">{JSON.stringify({ ...discussion }, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

const SendEmailModal = ({ userTo, userFrom, show, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState({
    recipients: [userFrom.email, userTo.email],
    cc: [WILLIAM],
    subject: "Mise en relation Linkera",
    message: `Chers ${userFrom.first_name} ${userFrom.last_name} et ${userTo.first_name} ${userTo.last_name},\n\nNous avons le plaisir de vous informer que votre NDA est signé et donc que votre demande de mise en relation est finalisée. Nous vous remercions de votre intérêt pour Linkera.\n\nSi vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter. Nous sommes là pour vous accompagner.\n\nCordialement,\nL'équipe Linkera`,
  });

  const handleSendEmail = async () => {
    try {
      if (!mail.recipients.length) return toast.error("Veuillez ajouter des destinataires.");
      setLoading(true);
      const { ok } = await api.post(`/email`, mail);
      setLoading(false);
      if (ok) toast.success("Email envoyé!");
      onClose();
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Erreur lors de l'envoi du mail.");
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="flex flex-col gap-2 p-3 pt-7 w-full">
        <div className="flex justify-between gap-2">
          <div className="w-2/3">
            <div className="px-1 text-sm text-gray-600 font-medium">Destinataires</div>
            <input
              disabled
              className="w-full rounded-md border-[1px] bg-gray-100 disabled:bg-gray-200 border-gray-300 shadow-sm md:text-sm placeholder:text-gray-300 py-2 px-2"
              name="destinataires"
              value={mail.recipients}
              onChange={(e) => setMail({ ...mail, recipients: e.target.value })}
            />
          </div>
          <div className="w-1/3">
            <div className="px-1 text-sm text-gray-600 font-medium">Copie</div>
            <input
              disabled
              className="w-full rounded-md border-[1px] bg-gray-100 disabled:bg-gray-200 border-gray-300 shadow-sm md:text-sm placeholder:text-gray-300 py-2 px-2"
              name="copie"
              value={mail.cc}
              onChange={(e) => setMail({ ...mail, recipients: e.target.value })}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="px-1 text-sm text-gray-600 font-medium">Objet</div>
          <input
            disabled={loading}
            className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-primary focus:outline-primary md:text-sm placeholder:text-gray-300 py-2 px-2"
            name="subject"
            value={mail.subject}
            onChange={(e) => setMail({ ...mail, subject: e.target.value })}
          />
        </div>
        <div className="mt-3 w-full">
          <div className="px-1 text-sm text-gray-600 font-medium">Message</div>
          <textarea
            disabled={loading}
            className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-primary focus:outline-primary md:text-sm placeholder:text-gray-300 py-2 px-2"
            rows="12"
            name="description"
            value={mail.message}
            onChange={(e) => setMail({ ...mail, message: e.target.value })}
          />
        </div>
        <div className="flex gap-1 items-center w-3/4 h-3/4 min-h-max min-w-max">
          <button
            disabled={loading}
            className="ml-2 hover:bg-primary text-primary font-semibold hover:text-white py-2 px-3 text-sm border border-primary hover:border-transparent rounded flex gap-2 items-center disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-wait"
            onClick={() => handleSendEmail()}>
            <IoPaperPlane />
            Envoyer
          </button>
        </div>
      </div>
    </Modal>
  );
};

const Infos = ({ discussion, setDiscussion, userTo, userFrom }) => {
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);

  // if creating is true, fetch data every 5 seconds until creating is false pandadoc_status is DRAFT
  useEffect(() => {
    setCreating(discussion.pandadoc_status === "UPLOADED");
  }, [discussion]);

  useEffect(() => {
    if (!creating) return;
    const interval = setInterval(() => {
      const fetchData = async () => {
        try {
          const res = await api.get(`/discussion/${discussion._id}`);
          if (!res.ok) throw new Error("Une erreur est survenue");
          setDiscussion(res.data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }, 5000);
    return () => clearInterval(interval);
  }, [creating]);

  const handleSendNDA = async () => {
    try {
      setLoading(true);
      const res = await api.post(`/discussion/${discussion._id}/send-nda`);
      if (res.ok) setDiscussion(res.data);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      {discussion.status !== "PENDING" && (
        <div className="col-span-2 border border-secondary rounded-lg p-5 flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <p className="text-primary text-xl font-medium flex gap-3 items-center mb-3">
              NDA
              {NDA_STATUS_SPAN[discussion.pandadoc_status]}
            </p>
            <div className="flex items-center gap-3">
              {creating ? (
                <div className="flex items-center gap-3">
                  <p className="text-primary text-sm font-medium">NDA en creation...</p>
                  <div className="border-t border-primary rounded-full w-6 h-6 animate-spin" />
                </div>
              ) : discussion.pandadoc_status === "document.uploaded" ? (
                <div className="border-t border-white rounded-full w-6 h-6 animate-spin" />
              ) : discussion.pandadoc_status === "document.draft" ? (
                <button type="button" className="primary-button" onClick={() => handleSendNDA()}>
                  Envoyer le NDA
                </button>
              ) : null}
              <a href={`https://app.pandadoc.com/a/#/documents/${discussion.pandadoc_id}`} target="_blank" rel="noopener noreferrer" className="empty-button">
                Voir sur Pandadoc
              </a>
            </div>
          </div>

          <p className="text-primary text-sm font-medium">
            Pandadoc ID: <span className="text-sm text-black">{discussion.pandadoc_id}</span>
          </p>
          <p className="text-primary text-sm font-medium">
            Pandadoc creation: <span className="text-sm text-black">{discussion.pandadoc_created_at}</span>
          </p>
          <p className="text-primary text-sm font-medium">
            Expiration: <span className="text-sm text-black">{discussion.pandadoc_expired_at ? new Date(discussion.pandadoc_expired_at).toLocaleDateString() : "N/A"}</span>
          </p>
          <p className="text-primary text-sm font-medium">
            Lien de signature du demandeur: <span className="text-sm text-black">{discussion.pandadoc_user_from_link}</span>
          </p>
          <p className="text-primary text-sm font-medium">
            Lien de signature du contacté: <span className="text-sm text-black">{discussion.pandadoc_user_to_link}</span>
          </p>
        </div>
      )}

      <div className="col-span-2 border border-secondary rounded-lg p-5 flex flex-col gap-2">
        <p className="text-primary text-xl font-medium flex gap-3 items-center mb-3">Statut: {STATUS_SPAN[discussion.status]}</p>
        <p className="text-primary text-sm font-medium">
          NDA Signé par le demandeur de mise en relation: <span className="text-sm text-black">{discussion.nda_user_from_signed ? "Oui" : "Non"}</span>
        </p>
        <p className="text-primary text-sm font-medium">
          NDA Signé par le contacté: <span className="text-sm text-black">{discussion.nda_user_to_signed ? "Oui" : "Non"}</span>
        </p>
      </div>
      <div className="col-span-2 border border-secondary rounded-lg p-5 flex flex-col gap-2">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h2 className="text-xl font-medium text-primary mb-4">Contacté</h2>
            <p className="text-primary text-sm font-medium">
              Prénom: <span className="text-sm text-black">{userTo.first_name}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Nom: <span className="text-sm text-black">{userTo.last_name}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              E-mail: <span className="text-sm text-black">{userTo.email}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Numéro: <span className="text-sm text-black">{userTo.phone}</span>
            </p>
          </div>
          <div>
            <h2 className="text-xl font-medium text-primary mb-4">Demandeur</h2>
            <p className="text-primary text-sm font-medium">
              Prénom: <span className="text-sm text-black">{userFrom.first_name}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Nom: <span className="text-sm text-black">{userFrom.last_name}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              E-mail: <span className="text-sm text-black">{userFrom.email}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Numéro: <span className="text-sm text-black">{userFrom.phone}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { IoCheckbox } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { RiDeleteBin4Line } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";

import ImageUploadSVG from "../../assets/svg/upload-image.svg";

import { MdEdit } from "react-icons/md";

import Modal from "../../components/Modal";
import api from "../../services/api";
import Switch from "../../components/Switch";
import {
  INVESTOR_TYPES,
  INVESTOR_MATURITIES,
  INVESTOR_BUSINESS_MODELS,
  INVESTOR_REGIONS,
  INVESTOR_SECTORS_PME,
  INVESTOR_SECTORS_STARTUP,
  BUYER_MATURITIES,
  BUYER_TYPES,
  INVESTOR_AMOUNT_RANGES_MAP,
} from "../../constants/investor";

const View = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [values, setValues] = useState({});
  const [user, setUser] = useState();
  const [view, setView] = useState("infos");
  const [isOpen, setIsOpen] = useState(false);
  const [elementIndex, setElementIndex] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/investor/${id}`);
        if (res.ok) {
          setData(res.data);
          setValues(res.data);

          const userRes = await api.get(`/user/${res.data.user_id}`);
          if (userRes.ok) setUser(userRes.data);
          else toast.error("Une erreur est survenue");
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  const handleChange = async (values) => {
    try {
      const res = await api.put(`/investor/${id}`, values);
      if (res.ok) {
        if (res.data.annonce_type === "buyer") toast.success("L'acquéreur a bien été modifiée");
        else toast.success("L'investisseur a bien été modifiée");
        setData(res.data);
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  const handleEdit = async (v) => {
    setValues((prev) => ({
      ...prev,
      team: prev.team.map((member, i) => (i === elementIndex ? v : member)),
    }));
    setIsOpen(true);
  };

  const handleDelete = async (l, index) => {
    setValues((prev) => ({
      ...prev,
      team: prev.team.filter((_, i) => i !== index),
    }));
  };

  const handleAdd = async () => {
    const newMember = {};
    setValues((prev) => ({
      ...prev,
      team: [...prev.team, newMember],
    }));
    setElementIndex(values.team.length);
    setIsOpen(true);
  };

  const hasChanged = (a, b) => {
    if (a.structure_name !== b.structure_name) return true;
    if (a.buyer_title !== b.buyer_title) return true;
    if (a.logo !== b.logo) return true;
    if (a.description !== b.description) return true;
    if (a.details !== b.details) return true;
    if (a.website !== b.website) return true;
    if (a.linkedin !== b.linkedin) return true;
    if (a.types?.join(",") !== b.types?.join(",")) return true;
    if (a.buyer_types?.join(",") !== b.buyer_types?.join(",")) return true;
    if (a.buyer_maturities?.join(",") !== b.buyer_maturities?.join(",")) return true;
    if (a.maturities?.join(",") !== b.maturities?.join(",")) return true;
    if (a.buyer_budget_min !== b.buyer_budget_min) return true;
    if (a.buyer_budget_max !== b.buyer_budget_max) return true;
    if (a.amount_ranges?.join(",") !== b.amount_ranges?.join(",")) return true;
    if (a.regions?.join(",") !== b.regions?.join(",")) return true;
    if (a.businesses?.join(",") !== b.businesses?.join(",")) return true;
    if (a.buyer_sectors?.join(",") !== b.buyer_sectors?.join(",")) return true;
    if (a.sectors?.join(",") !== b.sectors?.join(",")) return true;
    if (
      a.team?.length !== b.team?.length ||
      a.team?.some(
        (member, index) =>
          member.id !== b.team[index].id ||
          member.name !== b.team[index].name ||
          member.position !== b.team[index].position ||
          member.linkedin !== b.team[index].linkedin ||
          member.image !== b.team[index].image,
      )
    )
      return true;
    return false;
  };

  if (!data || !user) return <></>;

  return (
    <div className="flex flex-col p-8">
      <header className="w-full mb-8">
        <h1 className="text-3xl font-semibold text-black mb-2">
          {data.annonce_type === "buyer" ? "Acquéreur" : data.annonce_type === "investor" ? "Investisseur" : "Erreur type d'annonce"}
          {data.deleted_at && <span className="text-red-500 text-sm ml-2">Supprimé</span>}
        </h1>
      </header>

      <div className="flex justify-between mb-4">
        <div className="flex gap-5">
          <button type="button" className={`rounded-lg px-4 py-2 ${view === "infos" ? "bg-gray-100" : "border border-secondary"}`} onClick={() => setView("infos")}>
            Informations
          </button>
          <button type="button" className={`rounded-lg px-4 py-2 ${view === "raw" ? "bg-gray-100" : "border border-secondary"}`} onClick={() => setView("raw")}>
            Données brutes
          </button>
        </div>

        <div className="flex items-center gap-4">
          {hasChanged(values, data) && (
            <>
              <button type="button" className="empty-button" onClick={() => setValues(data)}>
                Annuler
              </button>
              <button type="button" className="primary-button" onClick={() => handleChange(values)}>
                Enregistrer
              </button>
            </>
          )}
          {data.deleted_at ? (
            <button type="button" className="primary-button" onClick={() => handleChange({ deleted_at: null })}>
              Restaurer
            </button>
          ) : (
            <button type="button" className="red-button" onClick={() => handleChange({ deleted_at: new Date() })}>
              Supprimer
            </button>
          )}
          <select className="select" value={data.annonce_status} onChange={(e) => handleChange({ annonce_status: e.target.value })}>
            <option value="DRAFT">Brouillon</option>
            <option value="PENDING">En attente</option>
            <option value="ACCEPTED">Acceptée</option>
            <option value="REJECTED">Refusée</option>
          </select>
        </div>
      </div>

      {view === "infos" ? (
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 border flex items-center border-secondary rounded-lg p-5 gap-4">
            <h1 className="text-xl font-medium text-primary">Afficher l'entreprise de manière anonyme sur la plateforme</h1>
            <Switch checked={data.confidential} onChange={(v) => handleChange({ confidential: v })} />
          </div>
          <div className="col-span-2 border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Créée par</h1>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-primary text-sm font-medium">
                  Prénom: <span className="text-sm text-black">{user.first_name}</span>
                </p>
                <p className="text-primary text-sm font-medium">
                  Nom: <span className="text-sm text-black">{user.last_name}</span>
                </p>
                <p className="text-primary text-sm font-medium">
                  E-mail: <span className="text-sm text-black">{user.email}</span>
                </p>
                <p className="text-primary text-sm font-medium">
                  Numéro: <span className="text-sm text-black">{user.phone}</span>
                </p>
              </div>
              <div>
                <p className="text-primary text-sm font-medium">
                  Créée le: <span className="text-sm text-black">{data.created_at?.toLocaleString("fr-FR")}</span>
                </p>
                <p className="text-primary text-sm font-medium">
                  Dernière modification le: <span className="text-sm text-black">{data.updated_at?.toLocaleString("fr-FR")}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-2 border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <div className="flex justify-between gap-5">
              <div className="flex flex-col gap-2 w-[70%]">
                {data.annonce_type === "buyer" ? (
                  <>
                    <h1 className="text-xl font-medium text-primary">Titre de l'annonce</h1>
                    <input
                      type="text"
                      name="title"
                      value={values.buyer_title || ""}
                      onChange={(e) => setValues({ ...values, buyer_title: e.target.value })}
                      className="w-full rounded-lg border px-3 py-2"
                    />
                  </>
                ) : (
                  <>
                    <h1 className="text-xl font-medium text-primary">Nom de la structure</h1>
                    <input
                      type="text"
                      name="title"
                      value={values.structure_name || ""}
                      onChange={(e) => setValues({ ...values, structure_name: e.target.value })}
                      className="w-full rounded-lg border px-3 py-2"
                    />
                  </>
                )}
              </div>
              <div className="flex flex-col gap-2 w-[30%]">
                <h1 className="text-xl font-medium text-primary">Logo</h1>
                <div className="w-24 h-24">
                  <ImageInput folder="/logo" name="logo" value={values.logo} onChange={(e) => setValues({ ...values, logo: e.target.value })} />
                </div>
              </div>
            </div>
            <h1 className="text-xl font-medium text-primary">Description</h1>
            <textarea
              rows={10}
              type="text"
              name="description"
              value={values.description || ""}
              onChange={(e) => setValues({ ...values, description: e.target.value })}
              className="w-full rounded-lg border px-3 py-2"
            />
            <h1 className="text-xl font-medium text-primary">Détails</h1>
            <textarea
              rows={10}
              type="text"
              name="description"
              value={values.details || ""}
              onChange={(e) => setValues({ ...values, details: e.target.value })}
              className="w-full rounded-lg border px-3 py-2"
            />
            <div className="flex items-center flex-wrap gap-5">
              <div className="flex flex-col w-[30%] gap-2">
                <h1 className="text-xl font-medium text-primary">Site Web</h1>
                <input
                  type="text"
                  name="title"
                  value={values.website || ""}
                  onChange={(e) => setValues({ ...values, website: e.target.value })}
                  className="w-full rounded-lg border px-3 py-2"
                />
              </div>
              <div className="flex flex-col w-[30%] gap-2">
                <h1 className="text-xl font-medium text-primary">LinkedIn</h1>
                <input
                  type="text"
                  name="title"
                  value={values.linkedin || ""}
                  onChange={(e) => setValues({ ...values, linkedin: e.target.value })}
                  className="w-full rounded-lg border px-3 py-2"
                />
              </div>
            </div>
            <h1 className="text-xl font-medium text-primary">Équipe</h1>
            <div className="flex flex-col gap-2">
              {values?.team.length !== 0 &&
                values?.team?.map((member, index) => (
                  <div
                    key={index}
                    className="appearance-none py-1  focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
                    <p>{member?.name}</p>
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          setElementIndex(index);
                          setIsOpen(true);
                        }}>
                        <MdEdit className="w-10 p-2 h-10 border rounded-md flex justify-center items-center cursor-pointer hover:bg-gray-50" />
                      </button>
                      <button onClick={() => handleDelete(member, index)}>
                        <RiDeleteBin4Line className="w-10 p-2 h-10 border rounded-md flex justify-center items-center  cursor-pointer hover:bg-gray-50" />
                      </button>
                    </div>
                  </div>
                ))}

              <button className="w-full bg-gray-200 hover:bg-gray-300 py-2 flex mt-5 justify-center items-center gap-3 rounded" onClick={() => handleAdd()}>
                Ajouter
                <FaPlus />
              </button>

              <Modal
                show={isOpen}
                onClose={() => {
                  setIsOpen(false);
                  setElementIndex(null);
                }}>
                <TeamModal setOpen={setIsOpen} team={values?.team[elementIndex]} setTeam={(v) => handleEdit(v)} />
              </Modal>
            </div>
          </div>

          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            {values.annonce_type === "buyer" ? (
              <>
                <h1 className="text-xl font-medium text-primary">Type de repreneur</h1>
                <MultiSelect options={BUYER_TYPES} values={values.buyer_types} onSelect={(v) => setValues({ ...values, buyer_types: v })} />
              </>
            ) : (
              <>
                <h1 className="text-xl font-medium text-primary">Type d'investisseur</h1>
                <MultiSelect options={INVESTOR_TYPES} values={values.types} onSelect={(v) => setValues({ ...values, types: v })} />
              </>
            )}
          </div>

          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Maturité des projets</h1>
            {values.annonce_type === "buyer" ? (
              <MultiSelect options={BUYER_MATURITIES} values={values.buyer_maturities} onSelect={(v) => setValues({ ...values, buyer_maturities: v })} />
            ) : (
              <MultiSelect options={INVESTOR_MATURITIES} values={values.maturities} onSelect={(v) => setValues({ ...values, maturities: v })} />
            )}
          </div>
          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Modèles économiques</h1>
            <MultiSelect options={INVESTOR_BUSINESS_MODELS} values={values.businesses} onSelect={(v) => setValues({ ...values, businesses: v })} />
          </div>
          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            {values.annonce_type === "buyer" ? (
              <>
                <h1 className="text-xl font-medium text-primary mb-3">Budget de reprise</h1>
                <div className="flex items-center gap-3">
                  <p className="text-primary text-xs font-medium">Min</p>
                  <input
                    type="number"
                    name="acquisition_min"
                    placeholder="Montant minimum"
                    value={values.buyer_budget_min}
                    onChange={(e) => setValues({ ...values, buyer_budget_min: e.target.value })}
                    className="input"
                  />
                </div>
                <div className="flex items-center gap-3">
                  <p className="text-primary text-xs font-medium">Max</p>
                  <input
                    type="number"
                    name="acquisition_max"
                    placeholder="Montant maximum"
                    value={values.buyer_budget_max}
                    onChange={(e) => setValues({ ...values, buyer_budget_max: e.target.value })}
                    className="input"
                  />
                </div>
              </>
            ) : (
              <>
                <h1 className="text-xl font-medium text-primary">Montants</h1>
                <MultiSelect
                  options={Object.keys(INVESTOR_AMOUNT_RANGES_MAP)}
                  values={values.amount_ranges}
                  onSelect={(v) => setValues({ ...values, amount_ranges: v })}
                  getLabel={(option) => INVESTOR_AMOUNT_RANGES_MAP[option]}
                />
              </>
            )}
          </div>
          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Zone géographique</h1>
            <MultiSelect options={INVESTOR_REGIONS} values={values.regions} onSelect={(v) => setValues({ ...values, regions: v })} />
          </div>
          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Secteurs</h1>
            {values.annonce_type === "buyer" ? (
              <MultiSelect options={INVESTOR_SECTORS_PME} values={values.buyer_sectors} onSelect={(v) => setValues({ ...values, buyer_sectors: v })} />
            ) : (
              <MultiSelect options={INVESTOR_SECTORS_STARTUP} values={values.sectors} onSelect={(v) => setValues({ ...values, sectors: v })} />
            )}
          </div>
        </div>
      ) : (
        <div className="col-span-2 bg-gray-900 rounded-lg p-5 flex flex-col gap-2">
          <pre className="text-white text-sm">{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

const MultiSelect = ({ options, values, onSelect, getLabel = (option) => option }) => {
  if (!options.length) return null;
  return (
    <div>
      <div className="flex flex-wrap gap-3">
        {options.map((option, i) => (
          <label
            key={i}
            className={`gap-2 w-auto items-center rounded p-2 flex cursor-pointer ${
              (values || []).includes(option) ? "border-2 border-primary text-primary" : "border border-gray-300 text-gray-500"
            }`}>
            <input
              type="checkbox"
              className="hidden"
              value={option}
              onChange={() => onSelect((values || []).includes(option) ? values.filter((v) => v !== option) : [...values, option])}
            />
            {(values || []).includes(option) ? <IoCheckbox className="text-lg" /> : <div className="border border-gray-600 rounded w-4 h-4"></div>}
            {getLabel(option)}
          </label>
        ))}
      </div>
    </div>
  );
};

const TeamModal = ({ setOpen, team = {}, setTeam }) => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <IoCloseSharp className="cursor-pointer text-xl" onClick={() => setOpen(false)} />
      </div>
      <div className="mt-5 flex flex-col gap-6">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">Nom</label>
          <input className="input" placeholder={"Prénom Nom"} value={team?.name || ""} onChange={(e) => setTeam({ ...team, name: e.target.value })} />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">Poste</label>
          <input className="input" placeholder={"Entrer"} value={team?.position || ""} onChange={(e) => setTeam({ ...team, position: e.target.value })} />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">LinkedIn</label>
          <input className="input" placeholder={"Entrer"} value={team?.linkedin || ""} onChange={(e) => setTeam({ ...team, linkedin: e.target.value })} />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">Photo</label>
          <div className="w-24 h-24">
            <ImageInput folder="/logo" name="image" value={team?.image} onChange={(e) => setTeam({ ...team, image: e.target.value })} />
          </div>
        </div>

        <div className="mt-8 mb-14 flex justify-end">
          <button className="empty-button" onClick={() => setOpen(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const ImageInput = ({ name, value, onChange, folder }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteImage = async () => {
    onChange({ target: { value: "", name } });
  };

  return (
    <>
      <label
        htmlFor={name}
        className={`bg-white mt-4 p-4 rounded-full w-full h-full ${
          value ? "hover:backdrop-blur-sm bg-white/10" : "hover:bg-gray-50"
        } hover:bg-gray-50 transition-all cursor-pointer flex flex-col justify-center items-center border border-secondary border-dashed`}>
        {loading ? (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
          </div>
        ) : value ? (
          <div className="relative">
            <img src={value} className="w-full h-full object-cover" />
            <button className="absolute -top-4 -right-4 w-6 h-6 bg-secondary rounded-full flex items-center justify-center" onClick={handleDeleteImage}>
              <MdDeleteOutline size={16} />
            </button>
          </div>
        ) : (
          <img src={ImageUploadSVG} className="w-1/3 h-1/3" />
        )}
      </label>
      <input
        id={name}
        type="file"
        className="hidden"
        disabled={loading}
        onChange={async (e) => {
          setLoading(true);
          const formData = new FormData();
          formData.append("files", e.target.files[0]);
          formData.append("folder", "avatar");
          const { data } = await api.postFormData(`/file`, formData);
          onChange({ target: { value: data[0], name } });
          setLoading(false);
        }}
      />
    </>
  );
};

export default View;
